<!--
 * @Author: your name
 * @Date: 2021-02-07 15:10:02
 * @LastEditTime: 2021-02-07 15:16:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\finance\client\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {}
</script>

<style></style>
